<template>
  <div class="CartItemsView">
    <CartItems />
  </div> <!-- /.CartItemsView -->
</template>

<script>
// import store from '@/store';
import {
  FETCH_CART,
} from '@/store/actions.type';
import {
//   RESET_STATE
  SET_CART_LOADING
} from '@/store/mutations.type';
import CartItems from '@/components/cart/CartItems';

export default {
  name: 'CartItemsView',
  components: {
    CartItems
  },
  created() {
    this.$store.commit(SET_CART_LOADING, true);
    this.$store.dispatch(FETCH_CART)
      .finally(() => {
        this.$store.commit(SET_CART_LOADING, false);
      });
  }
};
</script>

<style lang="scss">
.CartItemsView {
}
</style>